.report-index {
  .reports-table-container {
    padding-top: 20px;
    .table-top {
      .section {
        .report-date-selectors {
          display: flex;
          align-items: center;
          font-weight: bold;
          gap: 16px;
          input {
            margin-left: 0;
            width: 150px;
          }
        }
      }
    }
  }

  .page-content {
    padding: 10px;

    h1 {
      font-size: 15px;
      color: var(--dark-grey);
    }

    .tab-content {
      padding: 10px;
      background-color: var(--white);

      .rich-text-hat {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        font-weight: bold;
        border: 1px solid var(--grey-1);
        border-bottom: none;
        background: var(--main-background);
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0 20px 10px;
      background-color: var(--white);
      flex-direction: row;

      .ib-wrap {
        margin: 0 10px 0 0;
        height: 30px;
        max-width: fit-content;

        .ib-icon {
          min-width: 30px;
        }

        .ib-button {
          font-size: 12px;
        }
      }
    }
  }
}
