.report-row {
  td > .srr-link {
    color: var(--main-blue);
    cursor: pointer;
  }
  .rr-date-req {
    width: 8%;
  }
  .report-button {
    min-width: 100% !important;
    .ib-button {
      width: 100%;
      max-width: 100% !important;
      justify-content: start;
    }
  }
}
