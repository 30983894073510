.tab-content {
  .form-section {
    .form-section-head {
      .optional-header {
        color: var(--main-blue);
        border: 1px solid var(--main-blue);
        border-radius: 3px;
        padding: 2px;
        padding-left: 16px;
        padding-right: 16px;
        font-weight: 400;
      }
    }

    .form-section-body {
      .co2-vehicle-run-table {
        font-weight: 300;
        width: 100%;
        border-collapse: collapse;
        .value {
          text-align: right;
        }
        th {
          padding-bottom: 5px;
          border-bottom: 1px solid var(--white);
        }
        tr {
          border-bottom: 1px solid var(--border-outline);
        }

        th:first-child,
        td:first-child {
          width: 30%;
        }
        th:nth-child(2),
        td:nth-child(2) {
          width: 20%;
        }
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(4),
        td:nth-child(4),
        th:nth-child(5),
        td:nth-child(5),
        th:nth-child(6),
        td:nth-child(6),
        th:nth-child(7),
        td:nth-child(7),
        th:nth-child(8),
        td:nth-child(8) {
          width: 9.5%;
        }
        td {
          padding: 5px 0 5px 0;
          .missing {
            color: var(--grey-1);
          }
          &:last-child {
            white-space: nowrap;
          }
          &.bold {
            font-weight: 700;
          }
          div {
            width: fit-content;
            &.tooltip {
              &:hover {
                cursor: help;
              }
            }
          }
          .location {
            display: flex;
            flex-direction: row;
            gap: 4px;
            align-items: center;
            .location_buttons {
              width: fit-content;
              display: flex;
              flex-direction: row;
              gap: 4px;
              align-items: center;
              .google-maps-button {
                margin-right: 16px;
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--main-blue);
          &:visited {
            color: var(--main-blue);
          }
        }
      }
      .no-route {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        color: var(--grey-3);
      }
    }
  }
}
