.report-row {
  td > .srr-link {
    color: var(--main-blue);
    cursor: pointer;
  }
  .srr-parameters {
    max-width: max-content;
  }
  .srr-status {
    &.complete {
      color: var(--green);
    }
    &.failed {
      color: var(--red);
    }
    .status-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .status-icon {
        cursor: help;
      }
    }
  }
}
