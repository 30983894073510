.form-field {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 35% 65%;
  align-items: center;

  &.top {
    .form-field-label {
      margin: 16px 0 auto 0;
    }
  }

  .form-field-label {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    color: #555;
    white-space: pre;

    .form-field-note {
      margin: 0 0 0 6px;
      padding: 1px 8px 1px 8px;
      border-radius: 10px;
      background-color: var(--stack-blue);
      color: var(--white);
      font-size: 11px;
      cursor: pointer;
    }

    .form-field-restriction {
      margin: 0 0 0 6px;
      padding: 1px 8px 1px 8px;
      border-radius: 10px;
      background-color: var(--stack-blue);
      color: var(--white);
      font-size: 11px;
      cursor: help;

      &.hover-cell {
        text-align: initial;
        position: relative;
        overflow: initial !important;

        .hover-cell-info {
          display: none;
          position: absolute;
          width: 300px;
          color: var(--text);
          margin: 0;
          z-index: 1;
          word-wrap: normal;
          white-space: normal;
        }

        &:hover {
          cursor: help;

          .hover-cell-info {
            display: block;
            border: 1px solid;
            border-collapse: collapse;
            top: 32px;

            .top {
              border-bottom: 1px solid;
              padding: 10px 20px;
              background-color: var(--grey-1);
              font-weight: bold;
              margin: 0;
            }

            .main {
              padding: 10px 20px;
              margin: 0;
              background-color: var(--white);
            }
          }
        }
      }
    }
  }

  .checkbox {
    flex-direction: row-reverse;
    justify-content: space-between;

    .checkbox-text {
      margin: 0;
    }
  }

  input {
    color: var(--text);
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    padding: 6px 10px;
    outline: none;

    &:focus {
      border: 1px solid var(--dark-grey);
    }
  }

  textarea {
    color: var(--text);
    border: 1px solid var(--border-outline);
    border-radius: 4px;
    padding: 6px 10px;
    outline: none;

    &:focus {
      border: 1px solid var(--dark-grey);
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:disabled {
    background-color: var(--grey-1);
  }

  .if-wrap {
    display: flex;
    align-items: center;

    input {
      flex: 1;
      width: 64%;
      border-right: 0 transparent !important;
      border-radius: 4px 0 0 4px !important;
      margin-right: 0 !important;
    }

    .dd-main {
      flex: 1;
    }

    .minus {
      border-radius: 0 4px 4px 0;
      background-color: var(--red);
      width: 14%;
      height: 32px;
      color: var(--white);
      border: none;
      max-width: 32px;
      cursor: pointer;
    }

    .minus:disabled {
      background-color: var(--grey-1);
      width: 14%;
      height: 32px;
      color: var(--white);
      border: none;
      max-width: 32px;
      cursor: pointer;
    }
  }

  .button-input {
    display: flex;
    max-width: 100%;

    .dd-main,
    input {
      flex: 10;
      border-radius: 4px 0 0 4px;

      .dd-current {
        border-radius: 4px 0 0 4px;
      }
    }

    .plus {
      color: var(--white);
      min-width: 32px;
      height: 32px;
      border-radius: 0 4px 4px 0;
      border: none;
      cursor: pointer;

      &.plus {
        background-color: var(--green);
      }
    }
  }
  @media (max-width: 1550px) {
    grid-template-columns: 160px 300px;
    &.co2-date {
      grid-template-columns: 35% 65%;
    }
  }
}

.short {
  grid-template-columns: 35% 65%;
}
.short {
  grid-template-columns: 40% 60%;
}

.fill {
  grid-template-columns: 100px 1fr;
}

.blank {
  .form-field-label {
    height: 32px;
    margin: 0 -12px;
    background-color: var(--grey-2);
  }
}

.clickable {
  cursor: pointer;
  color: var(--main-blue) !important;

  input {
    cursor: pointer;
  }
}

.single {
  display: flex;
  justify-content: center;
  margin: 0 0 10px 0;

  .form-field-label {
    flex: 2;
    min-width: 100px;
    white-space: nowrap;
  }

  input {
    flex: 10;
  }

  .dd-main {
    flex: 10;
  }
}

.group {
  display: flex;
  justify-content: space-around;

  .vi-container:first-child {
    margin: 0 5px 0 0;
  }

  .vi-container:last-child {
    margin: 0;
  }

  .vi-container {
    margin: 0 5px 0 0;
  }
}

.bool {
  svg {
    margin: 0 0 0 94%;
    color: var(--text);
    font-size: 16px;
  }
}
