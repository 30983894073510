.tab-content {
  .no-jobs {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    color: var(--grey-3);
  }
  .optionalElement {
    .date-pickers {
      padding-right: 42px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      input {
        width: max-content;
      }
    }
  }
}
